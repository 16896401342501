.recos {
	@apply tw-w-full;
	min-height: 290px;
}

@screen md {
	.recos {
		@apply tw-mt-4;
		min-height: 320px;
	}
}