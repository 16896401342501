.hover-style {
	text-decoration: underline;

	.interior-lines {
		stroke: theme('colors.white.DEFAULT');
	}
	path.gcan0 {
		fill: theme('colors.gray.DEFAULT');
	}
}

.gcan {

	* {
		shape-rendering: crispEdges;
	}
	.gcan0 {
		fill:#FFFFFF;
		stroke:#333333;
		stroke-miterlimit:10;
	}
	.gcan1, .handle, .interior-lines {
		fill:none;
		stroke:#333333;
		stroke-miterlimit:10;
	}

	&:focus, &.focus,
	&:hover, &.hover {
		@extend .hover-style;
	}
}

:hover > .gcan {
	@extend .hover-style;
}
