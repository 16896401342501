.LoadingShimmer {
	&::after {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		transform: translateX(-100%);
		background-image: linear-gradient(
			90deg,
			rgba(#fff, 0) 0,
			rgba(#fff, 0.2) 40%,
			rgba(#fff, 0.5) 60%,
			rgba(#fff, 0)
		);
		animation: shimmer 1.5s infinite;
		content: "";
	}

	@keyframes shimmer {
		100% {
			transform: translateX(100%);
		}
	}
}

.Header {
	@apply tw-flex tw-justify-between;
	margin-bottom: 2px;
}

.LastModified {
	@apply tw-text-gray-300 tw-mb-4;
	margin-top: -15px;
}

.FavoritesImage {
	max-width: 100%;
	max-height: 100%;
}

.FavoritesImageHolder {
	@apply tw-flex tw-justify-center tw-items-center;
	max-height: 80px;
	max-width: 80px;
}

.FavoritesProducts, .LoadingImages {
	@apply tw-grid tw-grid-cols-4 tw-mb-4;
	gap: 10px;
}

.LoadingImages {
	@apply tw-relative tw-overflow-hidden;
	@extend .LoadingShimmer;
}

.LoadingSeeAllBlock {
	@apply tw-text-center tw-h-6 tw-relative tw-bg-gray-500 tw-bg-opacity-40 tw-border-none tw-border-b-0 tw-overflow-hidden;
	@extend .LoadingShimmer;
}

.NoFavorites {
	@apply tw-text-gray-300;
}

.LoadingImage {
	@apply tw-text-center tw-p-4 tw-h-14 tw-relative tw-bg-gray-500 tw-bg-opacity-40 tw-border-none tw-border-b-0 tw-overflow-hidden;

	width: 80px;
	height: 80px;
	max-width: 100%;
	max-height: 100%;
}
