@import 'css/lib/tailwind-custom/triangle';

.MagicTooltipMenu {
	.MagicTooltipMenu-edit {
		@apply tw-border-0 tw-underline tw-flex tw-items-center tw-m-0 tw-py-0 tw-pr-4 tw-pl-0;
		@apply tw-cursor-pointer tw-overflow-visible tw-relative tw-inline-block;
		background: none;
		font-size: inherit;
		font-family: inherit;
		&::before {
			content: ''
		}
		&::after {
			@apply tw-absolute tw-right-0;
			@include triangle(4px, theme('colors.blue.DEFAULT'), down);
			top: 50%;
		}
		&:global(.is-open)::after {
			@include triangle(4px, theme('colors.blue.DEFAULT'), up);
		}
		&:focus, &:hover {
			&::after {
				@include triangle(4px, theme('colors.gray.DEFAULT'), down);
			}
			&:global(.is-open)::after {
				@include triangle(4px, theme('colors.gray.DEFAULT'), up);
			}
		}
	}
	.MagicTooltipMenu-content {
		@apply tw-list-none tw-m-0;
		min-width: 178px;
	}
	.MagicTooltipMenu-link {
		& + .MagicTooltipMenu-link {
			@apply tw-mt-3 tw-mb-0 tw-mx-0;
		}
	}
}
