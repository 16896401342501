.sticky-summary {
	@apply tw-hidden tw-bottom-0 tw-self-start tw-content-start tw-bg-white;

	z-index: 1000;
	padding: 11px 26px 19px;

	&::before {
		content: '';
		top: 0;
		left: -4px;
		right: -4px;
		height: 7px;
		position: absolute;
		box-shadow: 0px -3px 2px rgba(204, 204, 204, 0.75);
	}


	&.visible-sticky-summary {
		@apply tw-sticky tw-block;

		@screen md {
			@apply tw-hidden;
		}
	}
}