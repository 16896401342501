.footer {
	@apply tw-grid tw-gap-x-3 tw-grid-flow-col tw-justify-start tw-pr-2 tw-py-4 tw-col-span-full tw-text-sm;

	@media print {
		@apply tw-hidden;
	}

	& > *:not(:last-child) {
		&:after {
			content: "|";
			align-self: center;
			margin-left: 12px;
		}
	}
}
