.footer-cart-id {
	@apply tw-flex tw-items-baseline;

	@screen md {
		@apply tw-mx-4;
	}
}

.footer-cart-id-label {
	@apply tw-pb-0;
}

.footer-cart-id-input {
	@apply tw-border-0 tw-font-normal;
	width: 135px;
}
