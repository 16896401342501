.footer-legal {
	@apply tw-py-4;
}

.footer-legal-links {
	@apply tw-flex tw-flex-wrap tw-justify-center tw-leading-10 tw-mb-2;

	@screen md {
		@apply tw-mb-4;
	}
}

.footer-legal-link-container {
	@apply tw-mx-2;

	@screen md {
		@apply tw-mx-4;
	}
}

.footer-legal-copyright {
	@apply tw-flex tw-flex-col tw-items-center tw-leading-10;

	@screen md {
		@apply tw-flex-row tw-justify-center;
	}
}

.footer-legal-copyright-text {
	@screen md {
		@apply tw-mx-4;
	}
}
