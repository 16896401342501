.LoadingDeliveryGroupHeader {
	@apply tw-relative tw-overflow-hidden tw-bg-gray-500 tw-mb-2;
	height: 22px;
}

.LoadingOrder {
	@apply tw-relative tw-overflow-hidden tw-bg-gray-600 tw-mb-4;
	height: 15px;
}

.DeliveryGroups {
	@apply tw-relative tw-overflow-hidden tw-grid tw-auto-rows-min tw-gap-y-5;
}

.DeliveryGroup {
	@apply tw-mb-5;
}

.SeeAllBlock {
	@apply tw-mt-6;
}
