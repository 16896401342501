.footer-email-sign-up {
	@apply tw-py-6;
	
	:global {
		form > div {
			@apply tw-flex tw-flex-col tw-items-center tw-w-full;

			@screen lg {
				@apply tw-flex-row tw-items-baseline tw-justify-center;
			}
		}

		legend {
			@apply tw-heading-4 tw-mb-4;

			@screen lg {
				@apply tw-mb-0 tw-mx-2;
			}
		}

		h2 {
			@apply tw-heading-4;
		}

		.email-field-group {
			@apply tw-mb-5;

			@screen lg {
				@apply tw-mb-0 tw-mx-2;
			}
		}

		input {
			@apply tw-placeholder-gray-300;
			width: 250px;

			@screen md {
				@apply tw-mr-1;
			}
		}

		p {
			@apply tw-mb-0 #{'!tw-text-xs'};

			@screen lg {
				@apply tw-mx-2 tw-text-2xs;
			}
		}
	}
}