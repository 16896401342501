.MagicRecos {
	padding: 10px;
}

.MagicRecos {
	[class~=MagicRecos-title] {
		margin: 16px 0 31px;
	}
}

@screen md {
	.MagicRecos {
		[class~=MagicRecos-title] {
			margin: 16px 0 26px;
		}
	}
}
