.icon {
	@apply tw-self-start tw-relative tw-text-center tw-flex tw-items-center;
	margin: 0 0 0 20px;
	width: 34px;
	height: 27px;

	svg {
		width: 100%;

		.pathFill {
			fill: theme('colors.white.DEFAULT');
		}
	}
}
