// need the media-nav-container rule to achieve greater specificicity when competing with .tw-not-sr-only
.MagicRecos-nav-container {
	.MagicRecos-navButton {
		@apply tw-bg-white tw-flex tw-items-center tw-justify-center tw-rounded-full tw-bottom-1/2 tw-z-10;
		// !important position: absolute rule to override .tw-not-sr-only which sets position: static and auto width/height
		@apply tw-absolute #{!important};
		height: 35px !important;
		opacity: 0.9;
		width: 35px !important;

		&:disabled {
			@apply tw-opacity-25;
		}

		&:before {
			@apply tw-block tw-border-gray tw-border-solid;
			content: '';
			height: 16px;
			width: 16px;
		}

		&:focus, &:hover {
			&:not(:disabled) {
				@apply tw-bg-gray tw-opacity-100;
				// !important position: absolute rule to override .tw-not-sr-only which sets position: static and auto width/height
				@apply tw-absolute #{!important};
				height: 35px !important;
				width: 35px !important;

				&:before {
					@apply tw-border-white;
				}
			}
		}
	}
}

.MagicRecos-nextButton {
	@apply tw-right-0;

	&:before {
		transform: rotate(-45deg) translateX(-3px) translateY(-3px);
		@apply tw-border-b tw-border-r tw-transform tw--rotate-45 tw--translate-x-1;
	}
}

.MagicRecos-prevButton {
	@apply tw-left-0;

	&:before {
		transform: rotate(45deg) translateX(-3px) translateY(3px);
		@apply tw-border-b tw-border-l tw-transform tw-rotate-45 tw-translate-x-1;
	}
}
