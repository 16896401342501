
@media screen {
	.recommendation-container {
		[class~=slick-track] {
			margin: 0;
		}

		[class~=slick-slider] {
			[class~=slick-nav-button] {
				top: calc(50% - 33px);
			}
		}

		[class~=slick-slider] [class~=slick-slide] > div {
			justify-content: left;
			align-items: flex-start;
		}
	}
}

@screen md {
	.recommendation-container {
		[class~=slick-slider] {
			padding: 0 16px;
		}
	}
	.recommendation-container-1600 {
		[class~=slick-track] {
			@apply tw-flex tw-mt-1;
			[class~=slick-slide] a {
				@apply tw-block tw-mx-auto;
			}
		}
	}
}
